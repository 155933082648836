import {protectedGetVars} from './protectedGetVars';
import {ProtectedGetVarsLinkFilter} from './ProtectedGetVarsLinkFilter';

function ready(fn) {
    if ((<any>document).attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
        fn();
    } else {
        if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', fn);
        } else {
            (<any>document).attachEvent('onreadystatechange', fn);
        }
    }
}

const protectedGetVarsLinkFilter: ProtectedGetVarsLinkFilter = new ProtectedGetVarsLinkFilter(protectedGetVars, window);

ready(function () {
    protectedGetVarsLinkFilter.applyProtectedGetVarsToLinks();
});
