export class ProtectedGetVarsLinkFilter {
    private protectedGetVars: Array<string>;
    private window: Window;

    public constructor(protectedGetVars: Array<string>, window: Window) {
        this.protectedGetVars = protectedGetVars;
        this.window = window;
    }

    public applyProtectedGetVarsToLinks() {
        const queryParameter: Array<string> =
            this.window.location.search
                .replace('?', '')
                .split('&');

        const queryParameterToAppend = queryParameter.filter((queryKeyValue: string): boolean => {
            let key: string = queryKeyValue.split('=')[0];

            return this.protectedGetVars.indexOf(key) !== -1;
        });

        if (queryParameterToAppend.length > 0) {
            var queryString = '?' + queryParameterToAppend.join('&');
            Array.prototype.forEach.call(document.querySelectorAll('a'), function (element) {
                var href = element.getAttribute('href') + queryString;
                element.setAttribute('href', href);
            });
        }
    }
}
