export const protectedGetVars: Array<string> = [
    'mitarbeiter',
    'code',
    'subcode',
    'kwtype',
    'gclid',
    'network',
    'device',
    'utm_content',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'msclkid',
    'trv_reference',
    'fbclid',
];
